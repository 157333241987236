import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import Card from '../components/Card'
import { ButtonLink } from '../components/styled/Button'

const Main = styled.main`
    margin: 0 var(--spacing-global);
`

const Header = styled.div`
    margin: 2rem 0;
    text-align: center;

    h1 {
        margin-bottom: 0.5rem;

        b {
            color: ${props => props.theme.brandColor2};
        }
    }

    h2 {
        font-size: 1.5rem;
    }
`

const ServiceGrid = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(
        auto-fit,
        minmax(calc(320px - var(--spacing-global) * 2), 1fr)
    );
`

const Box = styled(Card)`
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .gatsby-image-wrapper {
        max-height: 240px;
    }

    .content {
        padding: 1rem;
        margin-top: -2rem;
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        h3 {
            background-color: #fff;
            text-align: center;
            padding: 0.5rem;
            margin: 0 0.5rem 1rem;
        }
    }
`

const QueHacemos = ({ location }) => {
    const images = useStaticQuery(graphql`
        fragment ServiceImageData on File {
            childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        query {
            tiendasCorporativas: file(
                relativePath: { eq: "images/tiendas-corporativas-03.jpg" }
            ) {
                ...ServiceImageData
            }
            # comprasPersonalizadas: file(
            #     relativePath: { eq: "images/compras-personalizadas.jpeg" }
            # ) {
            #     ...ServiceImageData
            # }
            supermercadoEnLinea: file(
                relativePath: { eq: "images/supermercado-enlinea.jpeg" }
            ) {
                ...ServiceImageData
            }
            pedidosCorporativos: file(
                relativePath: { eq: "images/tiendas-corporativas-02.jpg" }
            ) {
                ...ServiceImageData
            }
        }
    `)
    return (
        <Layout
            seourl={location.pathname}
            seotitle="¿Que hacemos?"
        >
            <Main>
                <Header>
                    <h1>
                        Aprovechá tu tiempo <b>¡es tuyo!</b>
                    </h1>
                    <h2>Nosotros nos encargamos de tus compras</h2>
                </Header>
                <ServiceGrid>
                    {/* <Box>
                        <Img
                            fluid={
                                images.comprasPersonalizadas.childImageSharp
                                    .fluid
                            }
                        />
                        <div className="content">
                            <h3>Compras Personalizadas</h3>
                            <p>
                                Buscamos los productos que ocupés en el comercio
                                que nos indiqués.
                            </p>
                            <ButtonLink
                                title="Hacemos las compras por vos"
                                style={{ alignSelf: 'center' }}
                                to="/compras-personalizadas"
                            >
                                Mas Información
                            </ButtonLink>
                        </div>
                    </Box> */}
                    {/* <Box>
                        <Img
                            fluid={
                                images.supermercadoEnLinea.childImageSharp.fluid
                            }
                        />
                        <div className="content">
                            <h3>Supermercado en Línea</h3>
                            <p>
                                Seleccione productos de nuestro catalogo en
                                linea.
                            </p>
                            <ButtonLink style={{ alignSelf: 'center' }} to="/">
                                Ver Productos
                            </ButtonLink>
                        </div>
                    </Box> */}
                    <Box>
                        <Img
                            fluid={
                                images.tiendasCorporativas.childImageSharp.fluid
                            }
                        />
                        <div className="content">
                            <h3>Tiendas Corporativas</h3>
                            <p>
                                Operamos tiendas de conveniencia para sus
                                colaboradores.
                            </p>
                            <ButtonLink
                                title="Tus colaboradores necesitan tiendas"
                                style={{ alignSelf: 'center' }}
                                to="/tiendas-corporativas"
                            >
                                Mas Información
                            </ButtonLink>
                        </div>
                    </Box>
                    <Box>
                        <Img
                            fluid={
                                images.pedidosCorporativos.childImageSharp.fluid
                            }
                        />
                        <div className="content">
                            <h3>Pedidos Corporativos</h3>
                            <p>Todos los productos que su empresa necesite.</p>
                            <ButtonLink style={{ alignSelf: 'center' }} to="/pedidos-corporativos">
                                Mas Información
                            </ButtonLink>
                        </div>
                    </Box>
                </ServiceGrid>
            </Main>
        </Layout>
    )
}

export default QueHacemos
